<template>
  <div class="bg-smoke rounded mb-3">
    <Details
      :left="$t('reservationAndUsageHistory.changePetInfo')"
      :right="petInfoText"
      :aClass="'--medium-gray col-8'"
      format-text
    />
  </div>
</template>

<script>
import Details from './index'

export default {
  name: 'PetInfo',
  components: {
    Details
  },
  props: {
    petInfo: {
      type: [String, Object]
    },
    catAllowed: Boolean
  },
  computed: {
    petInfoText () {
      if (typeof this.petInfo === 'string') {
        return this.petInfo
      } else if (this.petInfo === null) {
        return '未選択\n\n  ※『予約情報変更』＞『ペット情報変更』よりペット詳細をご入力ください。'
      } else {
        if (!this.petInfo?.pets || this.petInfo?.pets.length === 0) {
          return '同伴なし'
        } else {
          return this.petInfo.pets.map((pet, i) => `ペット${i + 1}：
飼い主名：${pet.ownerName}
犬種${this.catAllowed ? '・猫種' : ''}：${pet.breed}
体重：${pet.petWeight}kg`).join('\n\n')
        }
      }
    }
  }
}
</script>
